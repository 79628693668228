import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Validator from 'validator';
import moment from 'moment';
import humanizeDuration from "humanize-duration"
import {
    DataFieldText,
    FieldContainer,
    LabelFieldText,
} from "../components/StyledComponents/StyledComponents";
import FieldView from "../components/FormFields/FieldView";
import { DJANGO_API, FRONTEND_URL } from "../utils/config";
import { METHODS, RESPONSE_STATUS } from "../utils/constants";
import appContext from "../providers/app/AppContext";
import pet_cry from '../assets/images/pet_cry.png'
import logo from '../assets/images/logoPetmimo.png'
import Loading from '../components/Loading';
import Resend from '../components/Resend';
import { colors } from '../utils/colors';

const DeleteAccount = () => {
    const app = React.useContext(appContext);
    const [email, setEmail] = React.useState('')
    const [isEmailReady, setIsEmailReady] = React.useState(false)
    const [showDeletedContent, setShowDeletedContent] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    const [code, setCode] = React.useState('')
    const [description, setDescription] = React.useState('');
    const [error, setError] = React.useState('')

    React.useEffect(() => {
        window.scrollTo(0, 0)
        const stored_email = window.localStorage.getItem("email")
        if (stored_email) {
            setEmail(stored_email)
            setIsEmailReady(true)
        }
        setIsLoading(false)
    }, []);


    const handleSendCode = (event) => {
        event.preventDefault()
        if (!Validator.isEmail(email)) {
            setError("Correo electrónico incorrecto")
        } else {
            setIsLoading(true)
            let url = `${DJANGO_API}/users/users/a/send_verification_code/`;
            let data = {
                email: email,
                action: 'delete'
            };
            setError("")
            app.axiosRequestApiKey(
                url,
                METHODS.POST,
                (res) => {
                    setIsLoading(false)
                    console.log(res);
                    setIsEmailReady(true)
                    window.localStorage.setItem("email", email)
                },
                data,
                (error) => {
                    setIsLoading(false)
                    console.log(error);
                    if (error.status === 'failed') {
                        setError(error.message);
                    }
                }
            );
        }
    }

    const deleteAccount = () => {
        let url = `${DJANGO_API}/users/users/a/destroy_by_web/`;
        let data = {
            email: email
        };
        setError("")
        app.axiosRequestApiKey(
            url,
            METHODS.POST,
            (res) => {
                setIsLoading(false)
                setIsEmailReady(false)
                setEmail('')
                setShowDeletedContent(true)
                window.localStorage.removeItem("email")
                console.log("Cuenta eliminada correctamente");
            },
            data,
            (error) => {
                setIsLoading(false)
                console.log(error);
                if (error.status === 'failed') {
                    setError(error.message);
                }
            }
        );
    }

    const addReason = () => {
        let url = `${DJANGO_API}/users/removal_reason/`;
        let data = {
            email: email,
            reason: description
        };
        setError("")
        app.axiosRequestApiKey(
            url,
            METHODS.POST,
            (res) => {
                console.log("Razon adicionada");
                deleteAccount()
            },
            data,
            (error) => {
                setIsLoading(false)
                console.log(error);
                if (error.status === 'failed') {
                    setError(error.message);
                }
            }
        );
    }

    const handleDeleteAccount = (event) => {
        event.preventDefault()
        setError('')

        if (description && Validator.isEmpty(description, { ignore_whitespace: true })) {
            setError("Descripción incorrecta")
        } else if (Validator.isEmpty(code, { ignore_whitespace: true })) {
            setError("Código incorrecto")
        } else {
            setIsLoading(true)
            let url = `${DJANGO_API}/users/users/a/check_verification_code/`;
            let data = {
                email: email,
                action: 'delete',
                code: code
            };
            setError("")
            app.axiosRequestApiKey(
                url,
                METHODS.POST,
                (res) => {
                    console.log("Codigo verificado correctamente");
                    if (description) {
                        addReason()
                    } else {
                        deleteAccount()
                    }
                },
                data,
                (error) => {
                    setIsLoading(false)
                    console.log(error);
                    if (error.status === 'failed') {
                        setError(error.message);
                    }
                }
            );
        }
    }

    return (
        <Wraper>
            <Container style={{ alignItems: 'center' }}>
                {
                    showDeletedContent ?
                        <React.Fragment>
                            <img height={50} src={logo} />
                            <LabelFieldText style={{ textAlign: 'center', maxWidth: '25rem', fontSize: '0.8rem' }}>Tu cuenta ha sido eliminada con éxito, así como todos los datos asociados. Puedes regresar cuando quieras, siempre serás bienvenido.</LabelFieldText>
                        </React.Fragment> :
                        <React.Fragment>
                            <img height={150} src={pet_cry} />
                            <H2>Lamentamos mucho que te vayas</H2>
                            <LabelFieldText style={{ textAlign: 'center', maxWidth: '25rem', fontSize: '0.8rem' }}>Te agradecemos por el tiempo que estuviste con PetMimo y por compartir tus experiencias en esta comunidad.</LabelFieldText>
                            {
                                !isEmailReady ?
                                    <Div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', maxWidth: '25rem' }}>
                                        <P >
                                            Mediantes esta pagina puedes solicitar la eliminacion de tu cuenta de PetMimo y todos los datos asociados a la misma.
                                        </P>
                                        <P >
                                            Luego de introducir tu correo electronico, recibiras en el mismo un codigo de verificacion, que nos permitira confirmar tu identidad
                                        </P>
                                        <P >
                                            En la siguiente pantalla:
                                            <ul style={{marginLeft: '1.5rem', marginTop: '1rem'}}>
                                                <li>
                                                    Puedes decirnos, de manera opcional,  la razon por la cual no continuas con nosotros.
                                                </li>
                                                <li>
                                                    Luego que introduzcas el codigo que recibiste por correo y dar clic en confirmar, tu cuenta y tus datos seran eliminados al instante.
                                                </li>
                                            </ul>

                                        </P>
                                        <Input type='text' placeholder='Correo electrónico' value={email} onChange={(event) => { setEmail(event.currentTarget.value) }} />
                                        {isLoading ?
                                            <Div style={{ display: 'flex', justifyContent: 'center' }}><Loading /></Div> :
                                            <Button disabled={isLoading} onClick={(event) => {
                                                handleSendCode(event)
                                            }}>Enviar c&oacute;digo</Button>
                                        }
                                        {error && <Div>{error}</Div>}
                                    </Div> :
                                    <Div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', maxWidth: '25rem' }}>
                                        <Div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            <FieldView field={"Correo electrónico"} data={email} />
                                            <Div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <LabelFieldText style={{ marginRight: '0.3rem', textAlign: 'left' }}>
                                                    No recibiste el c&oacute;digo?
                                                </LabelFieldText>
                                                <Resend email={email} action={'delete'} />
                                            </Div>

                                        </Div>

                                        {error && <Div>{error}</Div>}

                                        <Div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            <Label>Por qué te vas?</Label>
                                            <Textarea type='textarea' rows='4' placeholder='Cuentanos aqui...' value={description} onChange={(event) => { setDescription(event.currentTarget.value) }} />

                                            <Input type='text' placeholder='Código de verificación' value={code} onChange={(event) => { setCode(event.currentTarget.value) }} />

                                            {isLoading ?
                                                <Div style={{ display: 'flex', justifyContent: 'center' }}><Loading /></Div> :
                                                <Button disabled={isLoading} onClick={(event) => {
                                                    handleDeleteAccount(event)
                                                }}>Confirmar</Button>
                                            }
                                        </Div>
                                    </Div>
                            }
                        </React.Fragment>

                }

            </Container>
        </Wraper>
    );
};

export default DeleteAccount;
//Código
const Wraper = styled.main`
  background-color: #F5F5F5;
  padding: 5% 3%;
  height: 100%;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  color: #333333;
  text-align: justify;
`
const H2 = styled.h2`
  font-weight: 530;
  text-align: center;
`
const H3 = styled.h3`
text-align: center;
`
const P = styled.p`
font-size: 0.9rem;
line-height: 1.3rem;
`

const Input = styled.input`
height: 1.8rem;
font-size: 1rem;
border: none;
padding: 10px;
border-radius: 8px;
width: auto;
height: 2.5rem;
box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
`;
const Textarea = styled.textarea`
border-width: 1;
border-color: ${colors.grayExtraLigth};
padding: 10px;
border-radius: 15px;
width: auto;
resize: none;
box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
`;
const Label = styled.label`
`
const Button = styled.button`
font-size: 1em;
margin: 0.2em;
text-transform: uppercase;
padding: 0.8rem;
font-size: 0.8rem;
background-color: ${colors.orangeLigth};
color: #fff;
border: none;
border-radius: 8px;
`;
const Div = styled.div`
`;

const A = styled.a`
margin-left: 0.3rem;
color: ${colors.orangeLigth}
`;