import React from "react";
import styled from "styled-components";
import { Link as InternalLink } from "react-router-dom";
import DownloadOurApp from "./CoreLayout/DownloadOurApp";
import { colors } from "../utils/colors";
import { ContainerAnyDisplay } from "./StyledComponents/StyledComponents";
import FacebookLogo from "../assets/svg/FacebookLogo";
import YoutubeLogo from "../assets/svg/YoutubeLogo";
import InstagramLogo from "../assets/svg/InstagramLogo";
import TwitterXLogo from "../assets/svg/TwitterXLogo";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <ContainerAnyDisplay>
      <Wrap>
        <Colum1>
          <Subtitle>Más sobre PetMimo</Subtitle>
          <FooterLink>
            <a
              href="/aboutUs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Quiénes Somos
            </a>
          </FooterLink>
          <FooterLink>
            <a
              href="/workWithUs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Trabaja con Nosotros
            </a>
          </FooterLink>
          <FooterLink>
            <a
              href="/ourProducts"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Productos
            </a>
          </FooterLink>
          <FooterLink>
            <a
              href="/ourServices"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Servicios
            </a>
          </FooterLink>
          <SignApp>
            <Logo src="/images/Icons/Identidad.png" />
            <Copyray>
              © {currentYear} PetMimo - Todos los derechos reservados.
            </Copyray>
          </SignApp>
        </Colum1>
        <Colum1>
          <Subtitle>Enlaces de Interés</Subtitle>
          <FooterLink>
            {/* <InternalLink style={{ color: "black" }} to="privacyPolicy">
              Política de Privacidad
            </InternalLink> */}
            <a
              href="/privacyPolicy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Política de Privacidad
            </a>
          </FooterLink>
          <FooterLink>
            <a
              href="/termsAndConditions"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
            >
              Términos y Condiciones de Uso
            </a>
          </FooterLink>
        </Colum1>
        <Colum1>
          <Subtitle>Contáctenos</Subtitle>
          <FooterLink>Soriano 1111, Montevideo</FooterLink>
          <FooterLink>+598 95 893 067</FooterLink>
          <FooterLink>reach@petmimo.com</FooterLink>
          <FooterLink>Horario de atención: 8:00 - 18:00</FooterLink>
          <Icons>
            {/* <IconLink href="https://www.facebook.com/petmimo.app.3"> */}
            {/* <Icon src="/images/Icons/faceb_orange.png" /> */}
            <FacebookLogo />
            <YoutubeLogo />
            <InstagramLogo />
            <TwitterXLogo />

            {/* </IconLink> */}
            {/*  <Icon src="/images/Icons/youtub_orange.png" /> */}
            {/*  <Icon src="/images/Icons/instag_orange.png" /> */}
          </Icons>
        </Colum1>
        <Colum1>
          {/* <DownloadOurApp bgBtn={colors.orangeLigth} /> */}
          {/* <ContentQrApps>
          <CardQr />
          <CardQr />
        </ContentQrApps> */}
          <SignApp2>
            <Logo src="/images/Icons/Identidad.png" />
            <Copyray>
              © {currentYear} PetMimo - Todos los derechos reservados.
            </Copyray>
          </SignApp2>
        </Colum1>
      </Wrap>
    </ContainerAnyDisplay>
  );
}

export default Footer;

const Wrap = styled.div`
  width: 100%;
  background-color: white;
  color: black;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 50px;
  padding-top: 8rem;
  padding-bottom: 250px;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
`;

const Colum1 = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-items: center;
  }
`;
const Subtitle = styled.h3`
  margin: 10px;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayLigth};
`;

const FooterLink = styled.div`
  margin: 10px;
  font-weight: bold;
  font-size: 12px;
`;

const SignApp = styled.div`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
    flex-direction: column;
  }
`;
const SignApp2 = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.img`
  height: auto;
  width: 150px;
  margin-top: 60px;
`;

const Copyray = styled.p`
  margin-top: 10px;
  font-weight: 200;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grayLigth};
`;

const Icons = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 70px;
  gap: 20px;
`;

const IconLink = styled.a`
  display: flex;
`;

const Icon = styled.img`
  height: 30px;
  width: 30px;
`;

const ContentQrApps = styled.div`
  display: flex;
  justify-content: space-between;
  width: 310px;
  margin-top: 30px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-self: center;
  }
`;

const CardQr = styled.div`
  border-radius: 10px;
  height: 150px;
  width: 150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: red;
`;
